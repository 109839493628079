<template>
    <admin-table title="节假日设置" @search="handleSearch" :table="table" :loading="loading">
        <el-table-column type="index" width="50" align="center"/>
        <el-table-column align="center"
                         v-for="(v, idx) in table.columns"
                         :prop="v.field"
                         :label="v.title"
                         :width="v.width"
                         :formatter="v.formatter"
                         :key="idx">
            <template slot-scope="scope">
                <div v-if="v.field === 'dates'">
                    <el-tag v-for="item in scope.row[v.field]" :key="item" style="margin:2px">{{item}}</el-tag>
                </div>
                <div v-html='scope.row[v.field]' v-else></div>
            </template>
        </el-table-column>
        <el-table-column align="center" label="操作" width="180">
            <template slot-scope="scope">
                <el-button size="small" type="warning" icon="el-icon-edit" @click="handleEdit(scope.row)"/>
                <el-popover style="margin-left: 10px" :ref="`popover-${scope.$index}`" placement="top"
                            width="180">
                    <p>确定删除[{{scope.row.name}}]的记录吗？</p>
                    <div>
                        <el-button type="danger" size="mini" @click="handleDel(scope.row)">确定
                        </el-button>
                        <el-button size="mini" type="default"
                                   @click="$refs[`popover-${scope.$index}`].doClose()">取消
                        </el-button>
                    </div>
                    <el-button size="small" type="danger" slot="reference" icon="el-icon-delete"></el-button>
                </el-popover>
            </template>
        </el-table-column>
    </admin-table>
</template>

<script>
    export default {
        data() {
            function datesFormatter(t) {
                let res = []
                t.dates.forEach(e=>{
                    res.push(`<span>${e}</span>`)
                })
                return res
            }
            return {
                loading: false,
                table: {
                    buttons: [
                        {
                            type: 'default',
                            label: '导出Excel',
                            icon: 'el-icon-download',
                            path: '/main/worksheet/vacation/form'
                        },
                        {
                            type: 'primary',
                            label: '添加节假日',
                            icon: 'el-icon-plus',
                            path: '/main/worksheet/vacation/form'
                        }
                    ],
                    search: [
                        {
                            type: 'select',
                            key: 'area_id',
                            data: [
                                {
                                    label: '全部',
                                    value: ''
                                }
                            ],
                            class: 'hidden-md-and-down',
                            placeholder: '请选择来源'
                        },
                        {
                            type: 'input',
                            key: 'keywords',
                            placeholder: '请输入关键词'
                        }
                    ],
                    columns: [
                        {title: '名称', field: 'name', width: '200'},
                        {title: '日期', field: 'dates', width: '', formatter:datesFormatter},
                        // {title: '医生', field: 'doctor', width: '120'},
                        {title: '备注', field: 'desc', width: '200'},
                    ],
                    data: [],
                    total: 0,
                    page: 1,
                    pageSize: 10
                },
            }
        },
        methods: {
            handleEdit(row) {
                let path = `/main/worksheet/vacation/form?id=${row.id}`
                this.$store.commit('setRouterHistory', {label: `节假日设置 - ${row.name}`, path: path})
                this.$router.push({
                    path: path
                })
            },
            async handleDel(row) {
                const resp = await this.$http.delete(`/surgery/vacation/${row.id}`)
                this.$message.success(resp.data.data)
                this.getData()
            },
            handleSearch(e) {
                this.getData(e)
            },
            getData(params) {
                this.loading = true
                setTimeout(function () {
                    this.$http.get(`/surgery/vacation/`, {params: params}).then(resp => {
                        this.table.data = resp.data.data || []
                        this.table.total = resp.data.total || 0
                        this.loading = false
                    })
                }.bind(this), 200)
            }
        },
        mounted() {
            this.getData()
        }
    }
</script>

<style lang="scss" scoped>
</style>
